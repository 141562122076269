import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { AuthContext, Button, httpGet, httpPost, Modal, User, View } from "@streamy/common";
import { DeepMap, FieldError, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Event as BamboraEvent, ModalCheckout } from "@bambora/checkout-sdk-web";

interface PurchaserAddressFormData {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    street: string;
    zipCode: string;
    country: string;
    city: string;
}

interface addressVerificationStepProps {
    chosenAmount: number;
    handleAddressSubmit: Function;
    onInvalid?: Function;
    handleRegister: Function;
    addressRegister: Function;
    errors: DeepMap<PurchaserAddressFormData, FieldError>;
    errorMessage?: string;
    setPurchaseStep: Function;
    address: PurchaserAddressFormData;
    user: User | null;
    setModal: Dispatch<SetStateAction<boolean>>;
}

function AddressVerificationStep({
    chosenAmount,
    handleAddressSubmit,
    handleRegister,
    addressRegister,
    errors,
    errorMessage,
    setPurchaseStep,
    address,
    user,
    setModal,
}: addressVerificationStepProps) {
    return (
        <>
            <div className="text-2xl -mx-4 p-6 bg-brand-green-primary text-white mb-6">
                <p className="text-sm uppercase text-gray-200 font-black">STEP: 1 of 2</p>
                <p className="text-sm uppercase text-gray-100 font-black">Confirm billing address</p>
                Buy gift card for {chosenAmount} kr
            </div>
            <span className="pt-4">The gift card will be sent to your email address at:</span>
            <br />
            <div className="bg-gray-100 text-gray-900 border-2 border-gray-300  p-2 w-full mt-2">{user?.email}</div>
            <form className="p-0 flex flex-col " onSubmit={handleAddressSubmit(handleRegister)}>
                <fieldset className="pt-4 col-span-1">
                    <label className="block" htmlFor="register-first-name">
                        First name
                    </label>
                    <input
                        id="register-first-name"
                        type="text"
                        className="w-full p-1  border border-gray-300 rounded"
                        name="firstName"
                        autoComplete="given-name"
                        ref={addressRegister({ required: "Required field" })}
                    />
                    {errors.firstName && (
                        <span role="alert" className="text-red-500">
                            {errors.firstName.message}
                        </span>
                    )}
                </fieldset>
                <fieldset className="pt-4 col-span-1">
                    <label className="block" htmlFor="register-last-name">
                        Last name
                    </label>
                    <input
                        id="register-last-name"
                        type="text"
                        className="w-full p-1  border border-gray-300 rounded"
                        name="lastName"
                        autoComplete="family-name"
                        ref={addressRegister({ required: "Required field" })}
                    />
                    {errors.lastName && (
                        <span role="alert" className="text-red-500">
                            {errors.lastName.message}
                        </span>
                    )}
                </fieldset>
                <fieldset className="pt-4 col-span-2">
                    <label className="block" htmlFor="register-phone">
                        Phone number (including country code)
                    </label>
                    <input
                        id="register-phone"
                        type="text"
                        className="w-full p-1  border border-gray-300 rounded"
                        name="phoneNumber"
                        autoComplete="tel"
                        ref={addressRegister({
                            required: "Required field",
                            pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                        })}
                    />
                    {errors.phoneNumber && (
                        <span role="alert" className="text-red-500">
                            {errors.phoneNumber.message}
                        </span>
                    )}
                </fieldset>

                <fieldset className="pt-4">
                    <label className="block" htmlFor="register-street">
                        Street address
                    </label>
                    <input
                        id="register-street"
                        type="text"
                        className="w-full p-1  border border-gray-300 rounded"
                        name="street"
                        autoComplete="street-address"
                        ref={addressRegister({ required: "Required field" })}
                    />
                    {errors.street && (
                        <span role="alert" className="text-red-500">
                            {errors.street.message}
                        </span>
                    )}
                </fieldset>
                <fieldset className="pt-4">
                    <label className="block" htmlFor="register-zipcode">
                        Zip code
                    </label>
                    <input
                        id="register-zipcode"
                        type="text"
                        className="w-full p-1  border border-gray-300 rounded"
                        name="zipCode"
                        autoComplete="postal-code"
                        ref={addressRegister({ required: "Required field" })}
                    />
                    {errors.zipCode && (
                        <span role="alert" className="text-red-500">
                            {errors.zipCode.message}
                        </span>
                    )}
                </fieldset>
                <fieldset className="pt-4">
                    <label className="block" htmlFor="register-city">
                        City
                    </label>
                    <input
                        id="register-city"
                        type="text"
                        className="w-full p-1  border border-gray-300 rounded"
                        name="city"
                        autoComplete="address-level2"
                        ref={addressRegister({ required: "Required field" })}
                    />
                    {errors.city && (
                        <span role="alert" className="text-red-500">
                            {errors.city.message}
                        </span>
                    )}
                </fieldset>
                <fieldset className="pt-4">
                    <label className="block" htmlFor="register-country">
                        Country
                    </label>
                    <select
                        ref={addressRegister({ required: "Required field" })}
                        name="country"
                        id="register-country"
                        autoComplete="country"
                        className="w-full p-1  border border-gray-300 rounded"
                    >
                        <option value="">Select a country / region…</option>
                        <option value="AX">Åland Islands</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="PW">Belau</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">British Indian Ocean Territory</option>
                        <option value="BN">Brunei</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo (Brazzaville)</option>
                        <option value="CD">Congo (Kinshasa)</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">Heard Island and McDonald Islands</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="CI">Ivory Coast</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Laos</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia</option>
                        <option value="MD">Moldova</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="KP">North Korea</option>
                        <option value="MK">North Macedonia</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PS">Palestinian Territory</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russia</option>
                        <option value="RW">Rwanda</option>
                        <option value="ST">São Tomé and Príncipe</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="SX">Saint Martin (Dutch part)</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">South Georgia/Sandwich Islands</option>
                        <option value="KR">South Korea</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syria</option>
                        <option value="TW">Taiwan</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom (UK)</option>
                        <option value="US">United States (US)</option>
                        <option value="UM">United States (US) Minor Outlying Islands</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Vietnam</option>
                        <option value="VG">Virgin Islands (British)</option>
                        <option value="VI">Virgin Islands (US)</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                    </select>
                    {errors.country && (
                        <span role="alert" className="text-red-500">
                            {errors.country.message}
                        </span>
                    )}
                </fieldset>

                {errorMessage && (
                    <div className="p-3 mt-8 mb-2 text-xl text-white bg-red-500 mobile:text-lg">{errorMessage}</div>
                )}
                <Button
                    onClick={() => {
                        setPurchaseStep(2);
                        handleStreamyBuy(
                            chosenAmount,
                            address,
                            user,
                            () => {
                                setPurchaseStep(3);
                                setModal(true);
                            },
                            () => {
                                setModal(false);
                                setPurchaseStep(1);
                            }
                        );
                        setModal(false);
                    }}
                    className="mt-4 capitalize"
                >
                    Correct, take me to payment
                </Button>
            </form>
        </>
    );
}

interface purchaseStepProps {
    chosenAmount: number;
}

const handleStreamyBuy = async (
    giftcardAmount: number,
    address: PurchaserAddressFormData,
    user: User | null,
    finishedCallback?: Function,
    canceledCallback?: Function
) => {
    const checkout = new ModalCheckout(null);
    checkout.show();

    httpPost<{ bamboraCheckoutSessionId: string; orderId: string }>(
        "orders/initiate",
        JSON.stringify({ giftcardAmount: giftcardAmount, billingAddress: address, orderType: "giftCard" })
    )
        .then((data) => {
            checkout.initialize(data.bamboraCheckoutSessionId);
        })
        .catch((e) => {
            // TODO Show an error message
            canceledCallback && canceledCallback();
            checkout.destroy();
        });

    checkout.on(BamboraEvent.Authorize, () => {
        console.log("payment confirmed");
        finishedCallback && finishedCallback();
    });

    checkout.on(BamboraEvent.Cancel, () => {
        // TODO Send cancel event to backend
        // httpPost()
        canceledCallback && canceledCallback();
    });
};

const PurchaseStep = ({ chosenAmount }: purchaseStepProps) => {
    console.log("rerender");

    return (
        <>
            <div className="text-2xl p-6 bg-brand-green-primary text-white">
                <p className="text-sm uppercase text-gray-200 font-black">STEP: 2 of 2</p>
                <p className="text-sm uppercase text-gray-100 font-black">Pay</p>
                Buy gift card for {chosenAmount}
            </div>
            <div>Wait for payment modal</div>
        </>
    );
};

const PurchaseStepConfirm = () => {
    return (
        <>
            <div className="text-2xl p-6 bg-brand-green-primary text-white">
                <p className="text-sm uppercase text-gray-200 font-black">STEP: 2 of 2</p>
                <p className="text-sm uppercase text-gray-100 font-black">CONFIRMATION</p>
            </div>
            <div>Thank you. The gift card is being sent to your email address. </div>
        </>
    );
};

const BuyGiftcardPage = () => {
    //const [chosenAmount, setChosenAmount] = useState(200);
    const [modalVisible, setModalVisible] = useState(false);
    const [user, setUser] = useState<User | null>();
    const { isLoggedin } = useContext(AuthContext);
    const chosenAmount = 200;

    if (isLoggedin && !user) {
        httpGet<User>("users/me")
            .then((d: User) => {
                if (d) {
                    setUser(d);
                }
                return d;
            })
            .then((d) => {
                if (d?.address) {
                    setValue("city", d.address.city);
                    setValue("country", d.address.country);
                    setValue("zipCode", d.address.zipCode);
                    setValue("street", d.address.street);
                    setValue("firstName", d.name.firstName);
                    setValue("lastName", d.name.lastName);
                    setValue("phoneNumber", d.phoneNumber);

                    setBillingAddress({
                        phoneNumber: d.address.city,
                        firstName: d.address.country,
                        lastName: d.address.zipCode,
                        street: d.address.street,
                        zipCode: d.name.firstName,
                        country: d.name.lastName,
                        city: d.phoneNumber,
                    });
                }
            });
    }

    let defaultFormValues: object = {};

    const { register: addressRegister, handleSubmit: handleAddressSubmit, errors, setValue } = useForm<
        PurchaserAddressFormData
    >({
        defaultValues: { country: "NO", ...defaultFormValues },
    });

    const [billingAddress, setBillingAddress] = useState<PurchaserAddressFormData>({
        phoneNumber: "",
        firstName: "",
        lastName: "",
        street: "",
        zipCode: "",
        country: "",
        city: "",
    });

    const [purchaseStep, setPurchaseStep] = useState(1);

    const handleRegister = (formData: PurchaserAddressFormData) => {
        const { firstName, lastName, street, zipCode, country, city, phoneNumber } = formData;
        setBillingAddress({
            phoneNumber: phoneNumber,
            firstName: firstName,
            lastName: lastName,
            street: street,
            zipCode: zipCode,
            country: country,
            city: city,
        });
    };

    //const options = [{ value: 200, label: "200 kr" }];

    // const handleInputChange = (v: ValueType<{ value: number; label: string }>) => {
    //     // @ts-ignore
    //     setChosenAmount(v?.value);
    // };

    return (
        <>
            <View className="mobile:p-2">
                <div className="flex  max-w-6xl desktop:flex-row w-full mx-auto pb-10 min-h-full desktop:space-x-8 mobile:flex-col">
                    <section>
                        <h1 className="text-6xl desktop:w-1/2">Streamy giftcards</h1>
                        <p>
                            We offer gift cards you can virtually or physically put under the tree. Upon ordering you
                            will receive an email with a code and a nice pdf to print out. We do not offer refunds on
                            gift cards or on the money left in the gift card after purchase, but the credits left on the
                            gift card is stored on the receivers account and can be used towards their next purchase. It
                            is valid for 12 months.
                        </p>
                        <Link
                            to="/min-konto/gavekort"
                            className="text-4xl font-highlight text-brand-blue-primary hover:underline mt-16"
                        >
                            Redeem gift cards? Go here!
                        </Link>
                    </section>
                    <div className="mt-6 bg-brand-green-primary shadow shadow-xl w-full desktop:min-w-md h-full">
                        <h1 className="text-center p-4 text-white text-5xl">Gift card</h1>
                        <div className="w-full bg-gray-200 m-0">
                            <img alt="" src="/static/giftcardbg.png" className="w-full" />
                        </div>
                        {/*<p className="p-4 text-white">Choose the amount</p>
                        <Select
                            className="m-4"
                            onSelectResetsInput={false}
                            onBlurResetsInput={false}
                            options={options}
                            onChange={(v) => handleInputChange(v)}
                        />*/}
                        {isLoggedin ? (
                            <Button
                                onClick={() => {
                                    setModalVisible(!modalVisible);
                                }}
                                className="m-4"
                            >
                                Buy giftcard for {chosenAmount} NOK
                            </Button>
                        ) : (
                            <Link to="/min-konto" className="m-4 ">
                                <Button className="ml-4">Log in to purchase</Button>
                            </Link>
                        )}
                    </div>
                </div>
            </View>
            <Modal
                visible={modalVisible}
                className=" p-4 max-h-screen mobile:w-full  "
                closeModalFn={() => {
                    setModalVisible(false);
                    setPurchaseStep(1);
                }}
            >
                {user && purchaseStep === 1 && (
                    <AddressVerificationStep
                        chosenAmount={chosenAmount}
                        handleAddressSubmit={handleAddressSubmit}
                        handleRegister={handleRegister}
                        addressRegister={addressRegister}
                        errors={errors}
                        setPurchaseStep={setPurchaseStep}
                        address={billingAddress}
                        user={user}
                        setModal={setModalVisible}
                    />
                )}
                {user && purchaseStep === 2 && <PurchaseStep chosenAmount={chosenAmount} />}
                {user && purchaseStep === 3 && <PurchaseStepConfirm />}
            </Modal>
        </>
    );
};

export default BuyGiftcardPage;
