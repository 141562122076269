import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import "./nav-item.scss";

interface Props {
    label: string;
    to: string;
    className?: string;
    linkClassName?: string;
    style?: CSSProperties
}

export const NavItem = ({ label, to, className = "", linkClassName = "", style }: Props) => {
    return (
        <li className={"nav-item h-full flex items-center " + className} style={style}>
            <Link to={to} className={"font-medium mx-8 text-3xl tracking-wide font-highlight " + linkClassName}>
                {label}
            </Link>
        </li>
    );
};
