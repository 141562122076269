import { Event } from "@streamy/common";
import React from "react";
import { format } from "date-fns";
import "./event-card.scss";
import { Link } from "react-router-dom";
import clockIcon from "../../static/clock-icon.svg";
import { ReactComponent as TicketIcon } from "../../static/tickets-icon.svg";
import clsx from "clsx";

type EventCardProps = {
    event: Event;
    preventClicks?: boolean;
};

export function EventCard({ event, preventClicks }: EventCardProps) {
    const eventTime = event?.startDatetimeSeconds
        ? format(new Date(event?.startDatetimeSeconds * 1000), "dd.MMM yyyy HH:mm")
        : "";

    return (
        <div className="event-card" draggable={false}>
            <Link
                to={`/event/${event.slug}`}
                draggable={false}
                className={clsx({ "pointer-events-none": preventClicks })}
            >
                <div
                    className="event-card__background w-full h-full"
                    draggable={false}
                    style={{ backgroundImage: `url(/poster-default-thumb.png)` }}
                >
                    <div
                        className="event-card__background w-full h-full"
                        draggable={false}
                        style={{ backgroundImage: `url(${event.posterUrl || event.headerLowresUrl})` }}
                    />
                </div>
            </Link>

            <div className="event-card__info bg-black">
                <div className="event-card__info__category text-xl laptop:text-4xl bg-brand-green-primary grid items-center ">
                    <h2>{event.category}</h2>
                </div>
                <div className="event-card__info__subtitle text-xl laptop:text-4xl grid items-center">
                    <h2>{event.subtitle}</h2>
                </div>
                <div className="event-card__info__title text-4xl laptop:text-6xl grid items-center">
                    <h1>{event.title}</h1>
                </div>
                <div className="event-card__info__date flex text-sm laptop:text-lg items-center justify-start">
                    <div className={"mr-3 flex icon h-6 w-6"}>
                        <img src={clockIcon} alt="" className="w-full h-full" />
                    </div>
                    <p className="uppercase font-bold text-brand-black">{eventTime}</p>
                </div>
                {!event?.products && (
                    <Link
                        to={`/event/${event.slug}`}
                        draggable={false}
                        className={clsx(
                            "event-card__info__buy-ticket bg-brand-blue-primary text-white px-2 py-2 hover:bg-brand-blue-secondary text-3xl flex justify-center ",
                            { "pointer-events-none": preventClicks }
                        )}
                    >
                        <TicketIcon className="icon mr-1 -ml-3" />
                        <h3 className="text-md">Buy tickets</h3>
                    </Link>
                )}
            </div>
        </div>
    );
}
