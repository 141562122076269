import React from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends React.Component {
    state = {
        eventId: null,
        hasError: false,
    };

    static getDerivedStateFromError(error: any) {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <h1>An unexpected error occured</h1>
                    <p>Error ID: {this.state.eventId}</p>
                </>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary };
