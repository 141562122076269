import React from "react";
import { View } from "@streamy/common";

const WantToStreamPage = () => {
    return (
        <View>
            <div className="flex max-w-6xl flex-col w-full mx-auto pb-10">
                <h1 className="text-6xl">For artister</h1>
                Vil du streame på Streamy?
                <br />
                <br />
                Da bistår vi deg gjerne!
                <br />
                Du kan velge om du ønsker å bruke et profesjonelt studio, eller eller hjemmerigg.
                <br />
                Du bestemmer selv billettprisen, og vi har et team klart til å hjelpe deg å lykkes med din livestream.
                <br />
                Kontakt oss på kontakt@streamy.no
                <br />
            </div>
        </View>
    );
};

export default WantToStreamPage;
