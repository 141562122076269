import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import VisuallyHidden from "@reach/visually-hidden";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { format } from "date-fns";
import useSWR from "swr";
import { Button, Form, GiftCard, httpGet, httpPost, LoadingView } from "@streamy/common";
import * as QueryString from "query-string";

export const MyGiftcards = () => {
    const { data: giftcards, mutate } = useSWR<GiftCard[]>("users/giftcards", httpGet);

    const [showDialog, setShowDialog] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [redeemCode, setRedeemCode] = useState("");
    const { search } = useLocation();
    const { redeemCode: scannedRedeemCode } = QueryString.parse(search);
    const close = () => setShowDialog(false);

    if (!giftcards) {
        return <LoadingView label="Fetching your gift cards" />;
    }

    if (!redeemCode && scannedRedeemCode) {
        setRedeemCode(scannedRedeemCode.toString());
    }

    const handleSubmit = () => {
        setErrorMessage("");

        if (redeemCode) {
            httpPost<Response>("users/giftcards", JSON.stringify({ redeemCode }))
                .then((data) => {
                    setSubmitSuccess(true);
                    mutate();
                    close();
                    window.location.href = "/min-konto/gavekort";
                })
                .catch((e) => {
                    setErrorMessage(e.body.Message.replace("BadRequestError: ", ""));
                });
        } else {
            setErrorMessage("A code is required");
        }
    };

    return (
        <>
            <h1 className="text-7xl">GIFT CARDS</h1>
            <div className="mb-8">
                <Button className="mt-4" onClick={() => setShowDialog(true)}>
                    Redeem gift card
                </Button>
                {submitSuccess && (
                    <div className="w-full p-2 text-lg text-white bg-brand-green-primary mt-1 max-w-xl">
                        Found a gift card!
                    </div>
                )}
                <Dialog
                    aria-label="Redeem gift card"
                    isOpen={showDialog || !!scannedRedeemCode}
                    onDismiss={close}
                    className="rounded shadow-lg max-w-xl mobile:w-screen"
                >
                    <div className="flex flex-row w-full">
                        <h2 className="text-4xl font-highlight">Got a gift card for christmas?</h2>
                        <button className="close-button float-right ml-auto" onClick={close}>
                            <VisuallyHidden>Close</VisuallyHidden>
                            <svg
                                className="fill-current text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                            >
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                            </svg>
                        </button>
                        <div
                            onClick={close}
                            className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
                        >
                            <svg
                                className="fill-current text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                            >
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                            </svg>
                        </div>
                    </div>

                    <div>
                        <Form.FormInput
                            label="Card code"
                            value={redeemCode}
                            onChange={(e) => setRedeemCode(e.target.value)}
                        />
                        {errorMessage && (
                            <div className="w-full p-2 text-lg text-white bg-red-500 mt-1">{errorMessage}</div>
                        )}
                        <Button onClick={handleSubmit} color="green" className="mt-4">
                            Get your gift card / Løs inn kode
                        </Button>
                    </div>
                </Dialog>
            </div>

            <section className="grid grid-cols-2 laptop:grid-cols-1 mobile:grid-cols-1 mobile:min-h-screen ">
                {!giftcards ? <>You dont have any gift cards listed.</> : ""}
                {giftcards.map((giftCard) => {
                    return (
                        <div
                            key={giftCard.giftcardId}
                            className={
                                giftCard.lock && giftCard.lock.expiresDatetime * 1000 > Date.now()
                                    ? "rounded overflow-hidden shadow-lg mr-8 my-3 w-full bg-gray-200"
                                    : "rounded overflow-hidden shadow-lg mr-8 my-3 w-full"
                            }
                        >
                            <div className="px-6 py-4">
                                <p className="text-gray-700 text-base">
                                    Added at{" "}
                                    {format(new Date(giftCard.transferredDatetime * 1000), "dd MMM yyyy 'at' HH:mm")}.{" "}
                                </p>

                                <p className="text-gray-700 text-base pt-4">
                                    <p>
                                        <strong>Credits left</strong> {giftCard.amountLeft}.
                                    </p>

                                    <p>
                                        <strong>Credits original</strong> {giftCard.amountBought}.
                                    </p>
                                    {giftCard.lock && giftCard.lock.expiresDatetime * 1000 > Date.now() && (
                                        <p>
                                            <strong>Lock</strong> The gift card is locked to order{" "}
                                            {giftCard.lock.lockedToOrderId}.<br />
                                            <span className="text-gray-600 text-sm">
                                                It will time out at{" "}
                                                {format(
                                                    new Date(giftCard.lock.expiresDatetime * 1000),
                                                    "dd MMM yyyy 'at' HH:mm"
                                                )}
                                            </span>
                                        </p>
                                    )}
                                </p>
                            </div>
                            {giftCard.redeemCode && (
                                <div className="px-6 pt-4 pb-2">
                                    <span className="inline-block bg-brand-green-primary rounded px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
                                        Redeemed gift card code: {giftCard.redeemCode}
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                })}
            </section>
        </>
    );
};
