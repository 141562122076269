import React, { useContext } from "react";
import { AuthContext, View } from "@streamy/common";
import { Register } from "./Register";
import { Login } from "./Login";
import { NavLink, Route, Switch, useLocation } from "react-router-dom";
import { Me } from "../../views/account/Me";
import "./account-routes.scss";
import { Orders } from "../../views/account/Orders";
import { Tickets } from "../../views/account/Tickets";
import { ResetPassword } from "./ResetPassword";
import { Details } from "../../views/account/Details";
import * as QueryString from "query-string";
import { MyGiftcards } from "../../views/account/MyGiftcards";

interface Link {
    to: string;
    label: string;
}

const menuLinks: Link[] = [
    {
        to: "/min-konto",
        label: "Tickets",
    },
    {
        to: "/min-konto/gavekort",
        label: "Gift cards",
    },
    {
        to: "/min-konto/detaljer",
        label: "Account details",
    },
];

export const AccountRoutes = () => {
    const { userFetchStatus, user, isLoggedin, logout } = useContext(AuthContext);
    const { search } = useLocation();
    const { redirect } = QueryString.parse(search);

    if (!user && userFetchStatus === "pending") {
        return null;
    }

    if (!isLoggedin) {
        return (
            <Switch>
                <Route path="/min-konto/reset-passord" exact>
                    <View className="max-w-6xl mx-auto">
                        <ResetPassword />
                    </View>
                </Route>
                <Route>
                    <View className="max-w-6xl mx-auto block">
                        <h1 className="text-6xl px-4 py-12 w-full">Log in or create a new user account</h1>
                        <div className="flex mobile:flex-col">
                            <Login redirect={redirect} />
                            <Register redirect={redirect} />
                        </div>
                    </View>
                </Route>
            </Switch>
        );
    }

    return (
        <View className="text-xl mobile:px-6 mobile:w-full mt-8 mx-auto max-w-6xl ">
            <div className="flex mobile:flex-col">
                <div className="ml-6 mobile:ml-0 mobile:w-full laptop:pr-6 w-64 laptop:min-w-sm">
                    <h1 className="text-7xl">MY ACCOUNT</h1>
                    <Me />
                    <ul className="text-xl pt-3">
                        {menuLinks.map((link) => (
                            <li key={link.to}>
                                <NavLink
                                    to={link.to}
                                    className="text-blue-700 hover:text-blue-500 underline"
                                    activeClassName="text-blue-800"
                                >
                                    {link.label}
                                </NavLink>
                            </li>
                        ))}
                        <li>
                            <button
                                className="text-blue-700 hover:text-blue-500 underline mt-4"
                                onClick={() => logout()}
                            >
                                Logout
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="w-full desktop:min-w-xl">
                    <Switch>
                        <Route exact path="/min-konto">
                            <Tickets />
                        </Route>
                        <Route exact path="/min-konto/ordre">
                            <Orders />
                        </Route>
                        <Route exact path="/min-konto/gavekort">
                            <MyGiftcards />
                        </Route>
                        <Route path="/min-konto/billetter">
                            <Tickets />
                        </Route>
                        <Route path="/min-konto/detaljer">
                            <Details />
                        </Route>
                    </Switch>
                </div>
            </div>
        </View>
    );
};
