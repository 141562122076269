import React, { FormEvent, useEffect, useState } from "react";
import { Button, Event, httpGet, Input, View } from "@streamy/common";
import useSWR from "swr";
// @ts-ignore
import ReactJWPlayer from "react-jw-player";
import "./astrid-s.scss";

interface Props {
    setWhitelabel: (val: boolean) => void;
}

const AstridS = ({ setWhitelabel }: Props) => {
    const [codeInput, setCodeInput] = useState("");
    const [eventToken, setEventToken] = useState<string>();

    // this parameter is used to avoid blinking while waiting for sessionStorage
    // as long as it's undefined, it's not finished yet
    // false = no token in sessionStorage, true = token in sessionStorage
    const [cachedToken, setCachedToken] = useState<boolean>();

    useEffect(() => {
        setWhitelabel(true);

        return () => {
            setWhitelabel(false);
        };
    }, [setWhitelabel]);

    const { data, error, isValidating } = useSWR<Event>(
        eventToken ? "events/watch-vip/astrids?token=" + eventToken : null,
        httpGet,
        { shouldRetryOnError: false }
    );

    const fetchEvent = (e: FormEvent) => {
        e.preventDefault();
        setEventToken(codeInput);
    };

    useEffect(() => {
        const token = window.sessionStorage.getItem("astrids");
        if (token) {
            setCachedToken(true);
            setEventToken(token);
        } else {
            setCachedToken(false);
        }
    }, []);

    useEffect(() => {
        if (data && eventToken) {
            window.sessionStorage.setItem("astrids", eventToken);
        }
    }, [data, eventToken]);

    return (
        <View compact className={`astrids ${data ? "astrids--has-stream" : ""} pt-16 mobile:p-4`}>
            <div className="flex mobile:flex-col justify-between max-w-screen-desktop laptop:px-16 mx-auto">
                <section className="laptop:max-w-md mobile:pt-10 laptop:pb-16">
                    <img
                        className="pt-4 laptop:max-w-xl mr-auto"
                        src="/static/astrids/Astrid_S_Logo_Pink.svg"
                        alt="listen on spotify"
                    />
                    <h2 className="text-2xl">VIRTUAL CONCERT</h2>
                    <p className="alert p-3 mt-6">16 OCT 2020 17.00 CEST</p>
                    {!data && cachedToken === false ? (
                        <>
                            <article>
                                <p className="pt-8 pb-3">
                                    <span className="text-background-color regular-text">
                                        Astrid S is releasing her album "Leave it Beautiful" on Friday and you can view
                                        the exclusive virtual concert here on Streamy on the same day.
                                    </span>
                                </p>
                                <p className="pb-12">
                                    <span className="text-background-color">
                                        Login with your code to enter the livestream.
                                    </span>
                                </p>
                                <form className="flex w-3/4 mobile:w-full" onSubmit={fetchEvent}>
                                    <Input
                                        onChange={(e) => setCodeInput(e.target.value)}
                                        value={codeInput}
                                        className="mr-2"
                                        placeholder="Enter code"
                                    />
                                    <Button isLoading={!data && isValidating}>Submit</Button>
                                </form>
                            </article>

                            {error && (
                                <p className="alert w-3/4 mobile:w-auto p-3 mt-4">
                                    {error.body.Code && error.body.Code === "UnauthorizedError"
                                        ? "Wrong code, please try again"
                                        : "Something went wrong. Please try again"}
                                </p>
                            )}
                        </>
                    ) : null}
                </section>
                <section
                    className={"flex flex-col items-end " + (data && "laptop:mr-auto laptop:pl-10 spotify-holder")}
                >
                    <a
                        className="pt-4 pb-4 laptop:max-w-lg mobile:px-4 spotify-logo mobile:mx-auto"
                        href="https://open.spotify.com/playlist/6PpzA2iVtBq1vc0CMVxWuv?si=5uT2jYbxSniZRi_-gqPilQ"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="w-full"
                            src="/static/astrids/listen-on-spotify-v1.svg"
                            alt="listen on spotify"
                        />
                    </a>
                    {!data && cachedToken === false && (
                        <img
                            className="astrids-portrait p-4 mobile:p-0 mobile:my-4"
                            src="/static/astrids/Astrid_Pic.png"
                            alt=""
                        />
                    )}
                </section>
            </div>
            {eventToken && data && data.userHasAccess && data.displayVideo && (
                <div className={"astrids__stream event-page pt-8 " + (data.displayChat && " event-page--has-chat")}>
                    <div className="astrids__player event-page__player">
                        <ReactJWPlayer
                            playerId="jwplayer"
                            customProps={{ repeat: true }}
                            playerScript="https://cdn.jwplayer.com/libraries/iDuYbbO8.js"
                            file={data.hlsUrl}
                        />
                    </div>
                    {/*{eventToken && data && data.userHasAccess && data.displayChat && (*/}
                    {/*    <div className="relative event-page__chat astrids__chat">*/}
                    {/*        <Chat*/}
                    {/*            user={{*/}
                    {/*                userId: userId,*/}
                    {/*                address: undefined,*/}
                    {/*                email: "",*/}
                    {/*                name: { firstName: "guest", lastName: "" },*/}
                    {/*                phoneNumber: "",*/}
                    {/*                site: "",*/}
                    {/*                status: "unverified",*/}
                    {/*                type: "user",*/}
                    {/*                updated: 0,*/}
                    {/*                tokens: {*/}
                    {/*                    accessToken: "",*/}
                    {/*                    refreshToken: "",*/}
                    {/*                },*/}
                    {/*                receiveNewsletter: false,*/}
                    {/*            }}*/}
                    {/*            accessToken={eventToken}*/}
                    {/*            channel={"astrids"}*/}
                    {/*            className="astrids-chat"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            )}
            {eventToken &&
                data &&
                data.userHasAccess &&
                !data.displayVideo &&
                data.startDatetimeSeconds + data.durationMinutes * 60 > Date.now() / 1000 && (
                    <div className="p-10 mx-auto text-lg text-white event-page__notice bg-brand-black text-center laptop:w-8/12 regular-text">
                        The video stream will appear here in due time before the show. Stay tuned!
                    </div>
                )}
        </View>
    );
};

export default AstridS;
