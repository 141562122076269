import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Event } from "@streamy/common/src/types/event";
import "./event-hero.scss";

interface Props {
    event: Event;
}

export const EventHero = ({ event }: Props) => {
    const eventDate = useMemo(() => format(new Date(event.startDatetimeSeconds * 1000), "dd MMM yyyy"), [event]);
    const eventDateNonVisual = useMemo(() => format(new Date(event.startDatetimeSeconds * 1000), "MMMM yyyy"), [event]);

    return (
        <article className="event-hero max-w-screen-desktop mx-4 mobile:mx-0 laptop:w-full">
            <Link to={`/event/${event.slug}`} title={`${event.title}. ${eventDateNonVisual}`}>
                <div className="event-hero__background">
                    <img src={event.headerStandardUrl} alt="" />
                </div>
                <div className="event-hero__heading z-10">
                    <div className="event-hero__heading__content">
                        <h2 className="text-white text-7xl mobile:text-5xl">{event.title}</h2>
                        <div className="event-hero__heading-band mobile:flex-col">
                            <h2 className="event-hero__heading-band__name uppercase text-4xl text-black bg-brand-yellow px-6">
                                {event.subtitle}
                            </h2>
                            <h3 className="event-hero__heading-band__date uppercase text-white bg-brand-purple font-global px-6 py-3 text-xl">
                                {eventDate}
                            </h3>
                        </div>
                    </div>
                </div>
            </Link>
        </article>
    );
};
