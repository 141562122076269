import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, httpPost, User, AuthContext } from "@streamy/common";

interface LoginFormData {
    email: string;
    password: string;
}

interface AuthResponse {
    accessToken: string;
    refreshToken: string;
    user: User;
}

interface LoginProps {
    redirect: string | string[] | null;
}

export const Login = ({ redirect }: LoginProps) => {
    const { login } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const { register: loginRegister, handleSubmit: handleLoginSubmit, errors } = useForm<LoginFormData>();

    const handleLogin = (formData: LoginFormData) => {
        setErrorMessage("");
        httpPost<AuthResponse>("auth/login", JSON.stringify(formData))
            .then((data) => {
                login(data.accessToken, data.refreshToken, data.user);

                if (redirect) {
                    window.location.href = redirect.toString();
                }
            })
            .catch((e) => {
                setErrorMessage(e.body.Message.replace("UnauthorizedError: ", ""));
            });
    };

    return (
        <div className="login p-4 w-full flex flex-col">
            <h2 className="text-4xl pb-6">Login</h2>
            <form className="login__form p-4 border border-gray-400" onSubmit={handleLoginSubmit(handleLogin)}>
                <fieldset className="pt-4">
                    <label className="block" htmlFor="login-email">
                        Email
                    </label>
                    <input
                        id="login-email"
                        type="email"
                        className="w-full border-gray-300 border rounded p-1 text-xl"
                        name="email"
                        autoComplete="email"
                        ref={loginRegister({ required: "Field is required" })}
                    />
                    {errors.email && (
                        <span role="alert" className="text-red-500">
                            {errors.email.message}
                        </span>
                    )}
                </fieldset>
                <fieldset className="pt-4">
                    <label className="block" htmlFor="login-password">
                        Password
                    </label>
                    <input
                        id="login-password"
                        type="password"
                        className="w-full border-gray-300 border rounded p-1 text-xl"
                        name="password"
                        autoComplete="current-password"
                        ref={loginRegister({ required: "Field is required" })}
                    />
                    {errors.password && (
                        <span role="alert" className="text-red-500">
                            {errors.password.message}
                        </span>
                    )}
                </fieldset>
                <Button className="my-4">LOG IN</Button>
                {errorMessage && (
                    <div className="bg-red-500 text-white text-xl p-3 mobile:text-lg mb-2">{errorMessage}</div>
                )}
                <Link className="text-blue-700 hover:text-blue-500 underline " to="/min-konto/reset-passord">
                    Lost your password?
                </Link>
            </form>
        </div>
    );
};
