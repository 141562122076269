import React from "react";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { LoadingView, View, Event, httpGet } from "@streamy/common";
import { EventHero } from "../components/EventHero";

interface UrlParams {
    category: string;
}

const CategoryPage = () => {
    const { category } = useParams<UrlParams>();
    const { data } = useSWR<Event[]>("events/category/" + category, httpGet);

    if (!data) {
        return <LoadingView label={`Fetching ${category} events`} />;
    }

    return (
        <View>
            <div className="flex flex-col items-center w-full">
                {!data && <h1>No events in this category.</h1>}
                {data &&
                    data
                        .sort((ea, eb) => eb.startDatetimeSeconds - ea.startDatetimeSeconds)
                        .map((event) => <EventHero key={event.eventId} event={event} />)}
            </div>
        </View>
    );
};

export default CategoryPage;
