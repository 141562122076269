import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import {
    AuthContext,
    Button,
    Chat,
    Event,
    GiftCard,
    httpGet,
    httpPost,
    LoadingView,
    Modal,
    Product,
    View,
} from "@streamy/common";
import { format } from "date-fns";
import "./event-page.scss";
import { Event as BamboraEvent, ModalCheckout } from "@bambora/checkout-sdk-web";
import ReactMarkdown from "react-markdown";
import { useMediaQuery } from "react-responsive";
// @ts-ignore
import { ReactComponent as TicketIcon } from "../static/tickets-icon.svg";
import "../styles/dark-theme.scss";
import { StreamPlayer } from "../components/StreamPlayer";

interface UrlParams {
    slug: string;
}

type UseGiftCardModalProps = {
    //product: Product;
    isLoggedin: boolean;
    showModal: boolean;
    product?: Product;
    handleStreamyBuy: (productId: string, useGiftcard: UseGiftcard[]) => Promise<void>;
    setModal: Dispatch<SetStateAction<boolean>>;
    giftcards?: GiftCard[];
};

type UseGiftcard = {
    giftcardId: string;
    amount: number;
};

const UseGiftCardModal = ({
    isLoggedin,
    handleStreamyBuy,
    showModal,
    product,
    setModal,
    giftcards,
}: UseGiftCardModalProps) => {
    const [useGiftcards, setUseGiftcards] = useState<{ [id: string]: number }>({});

    let giftcardAmount = 0;

    if (!giftcards) {
        return <Modal closeModalFn={() => {}}>loading gift cards</Modal>;
    }

    if (giftcards) {
        giftcards.map((g) => {
            giftcardAmount += g.amountLeft;
            return giftcardAmount;
        });
    }

    let useAmount = 0;
    if (useGiftcards) {
        console.log(useGiftcards);
        useAmount = 0;
        Object.values(useGiftcards).forEach((val) => {
            if (val !== null && typeof val === "number") {
                console.log("is a number");
                // @ts-ignore
                useAmount += val;
            } else {
            }
        });
    }

    const mapGiftCardDictToArray = (giftCardDict: { [id: string]: number }): UseGiftcard[] => {
        return Object.keys(giftCardDict).map(
            (k): UseGiftcard => {
                return { giftcardId: k, amount: giftCardDict[k] };
            }
        );
    };

    return (
        <Modal
            visible={showModal}
            closeModalFn={() => {
                setModal(false);
            }}
        >
            <h1 className="text-center text-4xl px-4">Use your gift cards</h1>
            {giftcards?.map((giftCard) => {
                return (
                    <div
                        key={giftCard.giftcardId}
                        className={
                            "overflow-hidden my-2 w-full flex flex-row " +
                            (giftCard.lock ? "bg-gray-300 text-gray-700" : "")
                        }
                    >
                        <div className="px-6 py-4 w-1/2">
                            <p className="text-gray-700 text-base whitespace-no-wrap">
                                Added at{" "}
                                {format(new Date(giftCard.transferredDatetime * 1000), "dd MM yyyy 'at' HH:mm")}.{" "}
                            </p>

                            <p className="text-gray-700 text-base pt-3">
                                <strong>Credits left:</strong> {giftCard.amountLeft}
                            </p>
                            {giftCard.lock ? (
                                <p>
                                    This gift card is locked to a different payment attempt, lock expires:{" "}
                                    {format(new Date(giftCard.lock.expiresDatetime * 1000), "dd MM yyyy HH:mm")}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="px-6 pt-4 pb-2 flex flex-col w-1/2">
                            {giftCard.redeemCode && (
                                <>
                                    <span className="inline-block bg-brand-green-primary rounded px-3 py-1 text-sm font-semibold text-white mb-2">
                                        Gift card code: {giftCard.redeemCode}
                                    </span>

                                    <div className="flex w-full">
                                        Use:
                                        <input
                                            type="number"
                                            className="ml-auto border border-2 rounded w-24 pl-2"
                                            placeholder="0"
                                            name={giftCard.giftcardId}
                                            onChange={(value) => {
                                                const streamyCredits = parseInt(value.target.value);

                                                if (streamyCredits <= giftCard.amountLeft && streamyCredits >= 0) {
                                                    // @ts-ignore
                                                    setUseGiftcards({
                                                        ...useGiftcards,
                                                        [giftCard.giftcardId]: streamyCredits,
                                                    });
                                                }
                                            }}
                                        />{" "}
                                        kr
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
            <div className=" px-6 flex flex-row ">
                <div className="whitespace-no-wrap my-auto w-full flex flex-col">
                    <span>
                        Use {useAmount} of your {giftcardAmount} Streamy credits towards this purchase of{" "}
                        {product?.price}
                    </span>
                </div>
                <Button
                    className="whitespace-no-wrap"
                    onClick={() => {
                        if (product) {
                            handleStreamyBuy(product.productId, mapGiftCardDictToArray(useGiftcards));
                        }
                    }}
                >
                    TO PAYMENT
                </Button>
            </div>
            <span className="text-gray-400 uppercase text-xs px-6 font-bold">
                Note that we lock the gift card for an hour when attempting to place an order
            </span>
        </Modal>
    );
};

type ProductListingProps = {
    product: Product;
    isLoggedin: boolean;
    handleStreamyBuy: (productId: string, useGiftcards?: UseGiftcard[]) => Promise<void>;
    setProduct: Dispatch<SetStateAction<Product | undefined>>;
    setModal: Dispatch<SetStateAction<boolean>>;
    giftcards?: GiftCard[] | undefined;
};

const ProductListing = ({
    product,
    isLoggedin,
    handleStreamyBuy,
    setProduct,
    setModal,
    giftcards,
}: ProductListingProps) => {
    return (
        <React.Fragment key={product.productId}>
            <h2 className="mt-8 text-4xl border-b border-black">{product.name} </h2>

            {product.showPrice && <p>KR {product.price},-</p>}

            {product.external ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={product.externalUrl}
                    color={"white"}
                    className="buy-ticket-button bg-brand-blue-primary hover:bg-brand-blue-secondary text-xl"
                >
                    Buy ticket
                </a>
            ) : isLoggedin ? (
                <Button
                    className="buy-ticket-button bg-brand-blue-primary hover:bg-brand-blue-secondary text-xl"
                    onClick={() => {
                        console.log(giftcards);
                        if (giftcards && giftcards.length > 0) {
                            setProduct(product);
                            setModal(true);
                        } else {
                            handleStreamyBuy(product.productId);
                        }
                    }}
                >
                    Buy ticket
                </Button>
            ) : (
                <Link
                    to="/min-konto"
                    className="buy-ticket-button bg-brand-blue-primary hover:bg-brand-blue-secondary text-xl"
                >
                    Buy ticket
                </Link>
            )}
        </React.Fragment>
    );
};

type RegisterForFreeEventListingProps = {
    isLoggedin: boolean;
    event: Event;
    revalidate: Function;
};

interface RegisterResponse {}

const RegisterForFreeEventListing = ({ event, isLoggedin, revalidate }: RegisterForFreeEventListingProps) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleRegister = (event: Event) => {
        setLoading(true);
        httpPost<RegisterResponse>("users/tickets/event/" + event.eventId, JSON.stringify({}))
            .then((data) => {
                revalidate();
                setLoading(false);
            })
            .catch((e) => {
                if (e.body && e.body.Message && e.body.Message === "BadRequestError: Email already exists.") {
                    setErrorMessage("Ser ut til at du allerede har tilgang, gitt");
                } else {
                    setErrorMessage(e.body.Message.replace("UnauthorizedError: ", "").replace("BadRequestError: ", ""));
                }
            });
    };

    return (
        <React.Fragment key={"register-" + event.eventId}>
            <h2 className="mt-8 text-4xl border-b border-black">Register for access</h2>

            {isLoggedin ? (
                <Button
                    // className="mt-3 text-2xlw-fit inline-flex items-center text-center"
                    className="buy-ticket-button font bg-brand-blue-primary hover:bg-brand-blue-secondary text-xl"
                    onClick={() => {
                        handleRegister(event);
                    }}
                >
                    <div className="mx-auto inline-flex">
                        {loading && (
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" viewBox="0 0 24 24">
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        )}
                        Register for event
                    </div>
                </Button>
            ) : (
                <Link
                    style={{ color: "white" }}
                    to={"/min-konto?redirect=/event/" + event.slug}
                    className="buy-ticket-button font bg-brand-blue-primary hover:bg-brand-blue-secondary text-xl"
                    onClick={() => {}}
                >
                    Login to register
                </Link>
            )}
            {errorMessage ? <span className="bg-red-200">{errorMessage}</span> : ""}
        </React.Fragment>
    );
};

const EventPage = () => {
    const { slug } = useParams<UrlParams>();
    const { isLoggedin, user, accessToken } = useContext(AuthContext);
    const { data: event, mutate, revalidate } = useSWR<Event>(
        isLoggedin ? "events/watch/" + slug : "events/slug/" + slug,
        httpGet
    );

    useEffect(() => {
        document.querySelector("body")?.classList?.add?.("dark-theme");
        return () => {
            document.querySelector("body")?.classList?.remove?.("dark-theme");
        };
    }, []);

    const { data: giftcards } = useSWR<GiftCard[]>("users/giftcards", httpGet);

    const [modal, setModal] = useState(false);
    const [product, setProduct] = useState<Product>();

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const eventTime = event?.startDatetimeSeconds
        ? format(new Date(event?.startDatetimeSeconds * 1000), "dd MMM yyyy HH:mm")
        : "";

    const handleStreamyBuy = async (productId: string, useGiftCards?: UseGiftcard[]) => {
        console.log(useGiftCards);
        if (!event || !user) {
            console.log("no event and no user");
            return;
        }

        const checkout = new ModalCheckout(null);
        checkout.show();
        let orderId: string;

        httpPost<{ bamboraCheckoutSessionId: string; orderId: string; status?: string }>(
            "orders/initiate",
            JSON.stringify({ eventId: event.eventId, productId, useGiftCards })
        )
            .then((data) => {
                console.log(data);

                orderId = data.orderId;
                if (data.bamboraCheckoutSessionId) {
                    checkout.initialize(data.bamboraCheckoutSessionId);
                } else {
                    setTimeout(() => {
                        checkout.destroy();
                        revalidate();
                    }, 200);
                    setModal(false);
                }
            })
            .catch((e) => {
                // TODO Show an error message
                checkout.destroy();
            });

        checkout.on(BamboraEvent.Authorize, () => {
            httpPost("orders/frontend-confirm", JSON.stringify({ frontendConfirmsPayment: true, orderId: orderId }))
                .then(() => {
                    mutate({ ...event, hasAccess: true } as Event);
                })
                .catch((e) => {});
        });

        checkout.on(BamboraEvent.Cancel, () => {
            // TODO Send cancel event to backend
            // httpPost()
        });
    };

    if (!event) {
        return <LoadingView label="Loading event..." />;
    }

    return (
        <View
            compact
            className={`event-page place-items-center mx-auto max-w-screen-desktop relative ${
                event.displayChat ? "event-page--has-chat" : ""
            }`}
        >
            <div className="event-page__header header w-screen max-w-screen-desktop">
                <div
                    className={!event.displayVideo ? "header__background" : "header__backgroundactivestream"}
                    style={{ backgroundImage: `url(${isTabletOrMobile ? event.posterUrl : event.headerStandardUrl})` }}
                />
                <div className="z-10 header__content">
                    <div className="header__content-wrapper">
                        <div className="flex text-5xl event-tags font-highlight mobile:flex-col">
                            <span className="px-4 text-white uppercase bg-brand-green-primary mobile:w-fit">
                                {event.category}
                            </span>
                            <span className="px-4 uppercase bg-white mobile:hidden">{event.subtitle}</span>
                        </div>

                        <div className="event-title">
                            <h1 className="px-4 text-white uppercase bg-black text-7xl mobile:text-5xl">
                                {event.title}
                            </h1>
                        </div>

                        <div className="text-2xl bg-white event-times mobile:flex-col">
                            <div className="flex align-middle mobile:w-full">
                                <img className="h-8 my-auto mr-1" alt="" src="/static/time-icon.svg" />
                                <span className="mb-1 uppercase text-brand-black">{eventTime}</span>
                            </div>
                            <div className="flex align-middle mobile:w-full">
                                <img className="h-8 my-auto mr-1" alt="" src="/static/timeblast-icon.svg" />
                                <span className="mb-1 uppercase text-brand-black">{event.durationMinutes} MIN</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {event.userHasAccess &&
                !event.displayVideo &&
                event.startDatetimeSeconds + event.durationMinutes * 60 > Date.now() / 1000 && (
                    <div className="p-3 mx-auto text-lg text-white event-page__notice bg-brand-green-primary text-center w-full">
                        Showet vises her når det starter
                    </div>
                )}

            {event.userHasAccess && event.displayVideo && (
                <div className="event-page__player">
                    <StreamPlayer
                        hlsUrl={event.hlsUrl}
                        videoId={event.slug}
                        videoTitle={event.title}
                        viewerUserId={user?.userId}
                    />
                </div>
            )}

            {event.userHasAccess && event.displayChat && (
                <div className="relative event-page__chat">
                    <Chat user={user!} accessToken={accessToken!} channel={event.slug} />
                </div>
            )}

            <div className="flex max-w-6xl py-4 mx-auto break-words event-page__content mobile:pt-0 mobile:flex-col">
                <article
                    className="pt-4 pr-6 text-xl leading-relaxed mobile:p-4 mobile:order-1"
                    style={{ minHeight: "50vh" }}
                >
                    {event.textWhenAccess && <ReactMarkdown source={event.textWhenAccess} />}
                    <ReactMarkdown source={event.text} />
                </article>
                {!event.displayVideo && (
                    <section className="event-page__products min-w-sm laptop:max-w-sm p-4">
                        <div className="flex flex-col p-4 bg-gray-100 product w-full">
                            {!event.userMayHaveAccess ? (
                                <>
                                    <div className="flex align-middle">
                                        <TicketIcon className="h-10 w-10 my-auto" />
                                        <h1 className="pl-3 text-6xl uppercase">Tickets</h1>
                                    </div>

                                    {event.products?.map((product) =>
                                        ProductListing({
                                            product,
                                            isLoggedin,
                                            handleStreamyBuy,
                                            setModal,
                                            setProduct: setProduct,
                                            giftcards: giftcards,
                                        })
                                    )}
                                    {!event.products && <p className="pb-12">Not available for purchase</p>}

                                    {event.registerForFreeAccess && (
                                        <RegisterForFreeEventListing
                                            event={event}
                                            isLoggedin={isLoggedin}
                                            revalidate={revalidate}
                                        />
                                    )}

                                    <h2 className="pt-4 text-5xl">USE A TICKET CODE</h2>

                                    <p className="pt-2">
                                        Received a code, bought a ticket from another site like Tix or Ticketmaster, and
                                        want to redeem it?
                                    </p>

                                    <Link to="/min-konto" className="pt-4 text-blue-200 underline hover:text-blue-400">
                                        Yes, I have a code!
                                    </Link>

                                    <Link to="/faq" className="pt-4 text-blue-200 underline hover:text-blue-400">
                                        Read about our device support
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <h2 className="text-5xl">YOU HAVE ACCESS!</h2>

                                    <p>
                                        You already have a ticket for this event. You will be able to access the stream
                                        here once the event starts.
                                    </p>
                                </>
                            )}
                        </div>
                    </section>
                )}
            </div>
            {
                <UseGiftCardModal
                    isLoggedin={isLoggedin}
                    handleStreamyBuy={handleStreamyBuy}
                    product={product}
                    showModal={modal}
                    setModal={setModal}
                    giftcards={giftcards}
                />
            }
        </View>
    );
};

export default EventPage;
