import React, { useContext } from "react";
import { AuthContext } from "@streamy/common";

export const Me = () => {
    const { user, logout } = useContext(AuthContext);

    return (
        <>
            <p>
                Hello,{" "}
                {user?.name?.firstName || user?.name?.lastName ? (
                    <strong>
                        {user?.name.firstName} {user?.name.lastName}
                    </strong>
                ) : (
                    <strong>{user?.email}</strong>
                )}
                !
                <br />
                Not you?{" "}
                <button className="text-blue-700 hover:text-blue-500 underline" onClick={() => logout()}>
                    Logout
                </button>
            </p>
        </>
    );
};
