import React, { lazy, Suspense, useContext, useState } from "react";
import { ReactComponent as Logo } from "./static/streamy-mark.svg";
import { Link, Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Nav, NavItem } from "./components/Nav";
import { AccountRoutes } from "./components/AccountRoutes";
import { LoadingView, View, AuthContext } from "@streamy/common";
import "./app.scss";
import WantToStreamPage from "./views/WantToStreamPage";
import CategoryPage from "./views/CategoryPage";
import Landing from "./views/Landing";
import EventPage from "./views/EventPage";
import AstridS from "./views/AstridS";
import BuyGiftcardPage from "./views/BuyGiftcardPage";

const FAQ = lazy(() => import("./views/FAQPage"));
const About = lazy(() => import("./views/AboutPage"));
const PrivacyPolicy = lazy(() => import("./views/PrivacyPolicyPage"));
const TermsAndPolicy = lazy(() => import("./views/TermsAndConditionsPage"));

function App() {
    const matchAstrids = useRouteMatch({ path: "/astrids", exact: true });
    const { isLoggedin } = useContext(AuthContext);
    const [whitelabel, setWhitelabel] = useState(matchAstrids || false);

    return (
        <>
            <div className="flex flex-col h-screen">
                <header
                    id="streamy-header"
                    className={`justify-center block bg-gray-100 ${whitelabel ? "whitelabel" : ""}`}
                >
                    <div className="flex justify-center">
                        <Nav>
                            <ul className="flex items-center">
                                <li>
                                    <Link to="/" className="flex items-center bg-brand-black">
                                        <span className="px-3">
                                            <Logo />
                                        </span>
                                        <div className="relative flex px-3 py-1 text-4xl font-semibold text-white bg-brand-green-primary font-highlight">
                                            STREAMY
                                            <span
                                                className="absolute left-0 z-10 w-full text-lg font-normal tracking-widest text-center bg-brand-blue-primary"
                                                style={{ bottom: "-27px" }}
                                            >
                                                LIVING LIVE
                                            </span>
                                        </div>
                                    </Link>
                                </li>
                                {!whitelabel && (
                                    <Switch>
                                        <Route>
                                            <div className="flex mobile:hidden">
                                                <NavItem to="/event-type/comedy" label="COMEDY" />
                                                <NavItem to="/event-type/music" label="MUSIC" />
                                                <NavItem to="/event-type/podcast" label="PODCAST" />
                                            </div>
                                            <div className="flex mobile:bg-brand-green-primary mobile:h-full">
                                                <NavItem
                                                    to="/giftcards"
                                                    label="GIFT CARDS"
                                                    className="mx-0 "
                                                    linkClassName="mobile:mx-0 mobile:text-2xl "
                                                />
                                            </div>
                                        </Route>
                                    </Switch>
                                )}
                            </ul>
                            {!whitelabel && (
                                <ul className="flex items-center justify-end flex-grow">
                                    <Switch>
                                        <Route>
                                            <NavItem
                                                to="/min-konto"
                                                label={isLoggedin ? "MY ACCOUNT" : "LOGIN/REGISTER"}
                                                className="relative mr-4 text-white bg-brand-green-primary login-button mobile:w-full desktop:mr-px"
                                                style={{marginRight:"0.5rem"}}
                                                linkClassName="mobile:ml-auto mobile:text-2xl mobile:mr-2"
                                            />
                                        </Route>
                                    </Switch>
                                </ul>
                            )}
                        </Nav>
                    </div>
                </header>
                <Suspense fallback={<LoadingView />}>
                    <Switch>
                        <Route path="/" exact>
                            <Landing />
                        </Route>
                        <Route exact path="/astrids">
                            <AstridS setWhitelabel={setWhitelabel} />
                        </Route>
                        <Route path="/event/:slug">
                            <EventPage />
                        </Route>
                        <Route path="/min-konto">
                            <AccountRoutes />
                        </Route>
                        <Route path="/faq">
                            <FAQ />
                        </Route>
                        <Route path="/om-oss">
                            <About />
                        </Route>
                        <Route path="/personvern">
                            <PrivacyPolicy />
                        </Route>
                        <Route path="/terms-conditions">
                            <TermsAndPolicy />
                        </Route>
                        <Route path="/for-artister">
                            <WantToStreamPage />
                        </Route>
                        <Route path="/giftcards">
                            <BuyGiftcardPage />
                        </Route>
                        <Route path="/event-type/:category">
                            <CategoryPage />
                        </Route>
                        <Route path="/404" exact>
                            <View className="flex flex-col w-full max-w-6xl pb-10 mx-auto min-heigth-80vh">
                                <h1 className="text-7xl">404</h1>
                                Vi kan ikke finne denne siden. :(
                            </View>
                        </Route>
                        <Route>
                            <Redirect to="/404" />
                        </Route>
                    </Switch>
                </Suspense>
                <footer className="pt-6 text-white bg-brand-black laptop:px-4">
                    <div className="flex mx-auto text-2xl max-w-screen-desktop mobile:flex-col mobile:px-2">
                        <div className="pb-16 mr-10 laptop:pr-20">
                            <ul>
                                <Link className="underline hover:text-gray-200" to="/for-artister">
                                    Want to stream?
                                </Link>
                            </ul>
                            <ul>
                                <Link className="underline hover:text-gray-200" to="/personvern">
                                    Privacy policy
                                </Link>
                            </ul>
                            <ul>
                                <Link className="underline hover:text-gray-200" to="/terms-conditions">
                                    Terms & Conditions
                                </Link>
                            </ul>
                            <ul>
                                <Link className="underline hover:text-gray-200" to="/om-oss">
                                    About
                                </Link>
                            </ul>
                            <ul>
                                <Link className="underline hover:text-gray-200" to="/faq">
                                    FAQ
                                </Link>
                            </ul>
                        </div>
                        <div className="max-w-sm pb-5 text-xl">
                            This service is provided by Streamy.no. All rights reserved. All music licensed and approved
                            by{" "}
                            <a className="underline hover:text-gray-200" href="https://www.tono.no/">
                                TONO
                            </a>
                            . Unauthorized use or distribution of Streamy content is strictly prohibited, and will be
                            prosecuted.
                        </div>
                        <div className="laptop:flex-grow laptop:float-right">
                            <div className="relative w-32 h-full laptop:float-right">
                                <img className="bottom-0 laptop:absolute" alt="" src="/static/S-logomark-footer.svg" />
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default App;
