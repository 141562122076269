import { Event } from "@streamy/common/src/types/event";
import clsx from "clsx";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import bandEnd from "../../static/band-end.svg";
import clockIcon from "../../static/clock-icon.svg";
import { ReactComponent as TicketIcon } from "../../static/tickets-icon.svg";
import "./carousel-event.scss";
import { useMediaQuery } from "react-responsive";

interface Props {
    event: Event;
}

export const CarouselEvent = ({ event }: Props) => {
    const eventDateNonVisual = useMemo(() => format(new Date(event.startDatetimeSeconds * 1000), "MMMM yyyy"), [event]);
    const isMobile = useMediaQuery({ maxWidth: "768px" });

    const eventTime = event?.startDatetimeSeconds
        ? format(new Date(event?.startDatetimeSeconds * 1000), "dd.MMM yyyy HH:mm")
        : "";

    return (
        <article className={clsx("carousel-event max-w-screen-desktop mobile:mx-0")}>
            <div className="h-full">
                <Link to={`/event/${event.slug}`} title={`${event.title}. ${eventDateNonVisual}`}>
                    <div
                        className="event-card__background w-full h-full"
                        draggable={false}
                        style={{
                            backgroundImage: `url(/poster-default-thumb.png)`,
                            backgroundRepeat: "repeat",
                            backgroundSize: "auto",
                        }}
                    >
                        <div
                            className="carousel-event__background"
                            style={{
                                backgroundImage: isMobile
                                    ? `url(${event.posterUrl})`
                                    : `url(${event.headerHighresUrl})`,
                            }}
                        />
                    </div>
                </Link>
                <div className="carousel-event__heading z-10">
                    <div className="carousel-event__heading__content">
                        <div className="carousel-event__heading__content__category text-3xl bg-brand-green-primary grid items-center">
                            <h2>{event.category}</h2>
                        </div>
                        <div className="carousel-event__heading__content__subtitle text-3xl bg-black grid items-center">
                            <h2>{event.subtitle}</h2>
                        </div>
                        <div className="carousel-event__heading__content__title bg-black grid items-center">
                            <h1>{event.title}</h1>
                        </div>
                        <div className="carousel-event__heading__content__date flex place-items-center">
                            <div className={"grid place-content-center w-fit h-fit icon-wrapper"}>
                                <img src={clockIcon} alt="" className="icon" />
                            </div>
                            <p className="uppercase font-bold text-brand-black text-left text-md text-lg">
                                {eventTime}
                            </p>
                            <div className="carousel-event__heading__content__date__band-end h-8 pr-4 mobile:hidden ml-auto">
                                <img className="inline my-auto h-full" alt="" src={bandEnd} />
                            </div>
                        </div>
                        <Link
                            to={`/event/${event.slug}`}
                            className="carousel-event__heading__content__buy-ticket bg-brand-blue-primary text-white hover:bg-brand-blue-secondary text-3xl flex justify-center rounded rounded-l"
                        >
                            <div></div>
                            <TicketIcon className="icon mr-1 -ml-2" />
                            <h2>Buy tickets</h2>
                        </Link>
                    </div>
                </div>
            </div>
        </article>
    );
};
