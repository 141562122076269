import { Event } from "@streamy/common";
import clsx from "clsx";
import React, { ReactNode } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { EventCard } from "../EventCard";
import "./events-scroller.scss";

type EventScrollerProps = {
    title: ReactNode;
    events: Event[];
    className?: string;
};

export function EventsScroller({ events, title, className }: EventScrollerProps) {
    return (
        <div className={clsx("event-scroller mx-auto px-0 h-fit relative", className)}>
            {title}
            <div className="event-scroller__wrapper h-fit relative">
                <ScrollContainer className={clsx("event-scroller__content relative scrollbar-hide")}>
                    <div className="flex gap-8 laptop:gap-32 pb-8 w-fit h-fit relative px-4 desktop:px-0">
                        {events.map((event) => (
                            <EventCard event={event} />
                        ))}
                    </div>
                </ScrollContainer>
            </div>
        </div>
    );
}
