import React from "react";
import MuxPlayer from "@mux/mux-player-react";
// @ts-ignore
import ReactJWPlayer from "react-jw-player";

import { Event, User } from "@streamy/common";
// @ts-ignore
import initJWPlayerMux from "@mux/mux-data-jwplayer";

type StreamPlayerProps = {
    hlsUrl: Event["hlsUrl"];
    videoId: Event["slug"];
    videoTitle: Event["title"];
    viewerUserId?: User["userId"];
};

export function StreamPlayer({ hlsUrl, videoId, videoTitle, viewerUserId }: StreamPlayerProps) {
    const isMuxStream = hlsUrl?.startsWith("https://stream.mux.com");
    const muxPlaybackId = hlsUrl?.split(".com/")[1].split(".")[0];
    const jwplayer = (
        <ReactJWPlayer
            playerId="jwplayer"
            playerScript="https://cdn.jwplayer.com/libraries/iDuYbbO8.js"
            file={hlsUrl}
        />
    );
    const playerInitTime = initJWPlayerMux.utils.now();
    initJWPlayerMux(jwplayer, {
        debug: false,
        data: {
            env_key: "s0dst2s5d4jbmi5tpuolp3b04", // required

            // Metadata
            player_name: "jwplayer m mux", // ex: 'My Main Player'
            player_init_time: playerInitTime, // ex: 1451606400000
            video_id: videoId,
            video_title: videoTitle,
            viewer_user_id: viewerUserId,

            // ... and other metadata
        },
    });

    if (isMuxStream) {
        return (
            <MuxPlayer
                playbackId={muxPlaybackId}
                streamType="live:dvr"
                // @ts-ignore
                style={{ aspectRatio: "16/9", backgroundColor: "lightgray", "--seek-live-button": "none" }}
                title={videoTitle}
                metadata={{
                    video_id: videoId,
                    video_title: videoTitle,
                    viewer_user_id: viewerUserId,
                }}
            />
        );
    }

    return jwplayer;
}
