import { Event, httpGet, LoadingView, View } from "@streamy/common";
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import useSWR from "swr";
import { CarouselEvent } from "../components/CarouselEvent";
import { EventsScroller } from "../components/EventsScroller";
import "../styles/dark-theme.scss";

const Landing = () => {
    const { data: futureEvents } = useSWR<Event[]>("events/future-events", httpGet);
    const { data: highlightsEvents } = useSWR<Event[]>("events/highlights-events", httpGet);
    const { data: pastEvents } = useSWR<Event[]>("events/past-events", httpGet);
    const isMobile = useMediaQuery({ maxWidth: "768px" });

    useEffect(() => {
        document.querySelector("body")?.classList?.add?.("dark-theme");
        return () => {
            document.querySelector("body")?.classList?.remove?.("dark-theme");
        };
    }, []);

    if (!futureEvents) {
        return <LoadingView label="Loading shows..." />;
    }

    if (!pastEvents) {
        return <LoadingView label="Loading shows..." />;
    }

    return (
        <View compact>
            {/*<div className="flex flex-col items-center w-full mobile:p-4 max-w-screen-desktop mx-auto pb-4">
                <div className="border border-4 border-brand-yellow w-full max-w-screen-desktop">
                    <div className="bg-brand-yellow p-4">INFORMATION</div>
                    <div className="p-4">
                        Vi har for tiden problemer mellom vår betalingspartner og Evry sine systemer. Kunder i flere
                        banker vil kunne oppleve å ikke få betale.
                    </div>
                </div>
            </div>*/}
            {highlightsEvents && (
                <div className="flex flex-col items-center w-full ">
                    <div className="flex flex-col items-center w-full mobile:p-0 max-w-screen-desktop mx-auto pb-4">
                        {/* Read about carousel configuration here: https://github.com/leandrowd/react-responsive-carousel  */}
                        <Carousel
                            className="self-center w-full"
                            showStatus={false}
                            showIndicators={false}
                            centerMode={true}
                            centerSlidePercentage={100}
                            thumbWidth={100}
                            autoPlay={true}
                            transitionTime={isMobile ? 200 : 800}
                            interval={5000}
                            infiniteLoop={true}
                            useKeyboardArrows={true}
                            autoFocus={true}
                            swipeable={true}
                            swipeScrollTolerance={20}
                            preventMovementUntilSwipeScrollTolerance={true}
                        >
                            {highlightsEvents
                                .sort((ea, eb) => ea.startDatetimeSeconds - eb.startDatetimeSeconds)
                                .map((event) => (
                                    <CarouselEvent key={event.eventId} event={event} />
                                ))}
                        </Carousel>
                    </div>
                </div>
            )}
            <EventsScroller
                title={
                    <h1 className="text-6xl w-full max-w-screen-desktop mx-auto px-4 desktop:px-0">Upcoming shows</h1>
                }
                events={futureEvents.sort((ea, eb) => ea.startDatetimeSeconds - eb.startDatetimeSeconds)}
                className={"laptop:pb-16"}
            />

            <EventsScroller
                title={<h1 className="text-6xl w-full max-w-screen-desktop mx-auto px-4 desktop:px-0">Watch now</h1>}
                events={pastEvents.sort((ea, eb) => eb.startDatetimeSeconds - ea.startDatetimeSeconds)}
                className={"laptop:pb-16"}
            />
        </View>
    );
};

export default Landing;
